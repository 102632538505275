:root {
  --color-brand-navy: #0F468C;
  --color-red: #fd4332;
  --color-red-5: #ff4d4f;
  --color-yellow-5: #ffec3d;
  --bg-color-red-light: rgba(253, 67, 50, 0.1);
  --bg-color-orange-5: #ffa940;
}
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #5d4ebf;
}

@media (max-width: 767px) {
  .responsive-table thead {
    display: none;
 }
  .responsive-table tbody tr {
    display: block;
    border: 1px solid #c9cfdf;
    border-radius: 5px;
    margin-bottom: 1.3333rem;
    padding: 8px 16px;
 }
  .responsive-table tbody td {
    display: block;
    height: auto !important;
    padding: 8px !important;
 }
  .responsive-table tbody td:last-of-type {
    border-bottom: 0 !important;
 }
  .responsive-table tbody td::before {
    content: attr(data-label);
    float: left;
    font-weight: 500;
    display: block;
    color: #626262;
    padding-right: 7px;
    width: 50%;
    text-align: right;
 }
}
@media (max-width: 480px) {
  .responsive-table tbody td {
    text-align: left;
 }
  .responsive-table tbody td::before {
    float: none;
    padding-bottom: 5px;
 }
}
